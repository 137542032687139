import React, { useState, useEffect } from "react";
import CourseService from "../../services/CourseService";
import formloader from "../../images/formloading.svg";
import CkEditor from "./CkEditor";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import TestCaseService from "../../services/TestCaseService";
import { SortableContainer } from "react-sortable-hoc";
import { VscCheck } from "react-icons/vsc";
import { FcCancel } from "react-icons/fc";


export default function AddTrininigPlan(props) {
  const token = localStorage.getItem("token")
  const initalExamDetails = {
    data: "",
    time: 10,
    remainder: 5,
    difficulty: "",
    testcase: []
  };
  const [practical, setPractical] = useState(
    props.updateData || {
      cid: "",
      type: "",
      subQuestions: [],
      questionTopic: "",
      testCaseJson: "",
      ...initalExamDetails,
    }
  );
  const [scnames, setSCnames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [msg, setMsg] = useState({ success: false, msg: "" });
  const [selectedOption, setSelectedOption] = useState([]);
  const [testCaseOption, setTestcaseOption] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [addSubTopicName, setAddSubTopicName] = useState({ topicName: [] });
  const [toggleSelectDepartment, setToggleSelectDepartment] = useState(false);
  const [testCaseStatus, setTestCaseStatus] = useState(false)
  const navigate = useNavigate();

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setPractical((d) => ({ ...d, data: data }));
  };

  const handleAddTopic = () => {
    // e.preventDefault();
    const token = localStorage.getItem("token");
    if (token) {
      if (practical.cid) {
        CourseService.addSubTopicName(addSubTopicName, practical.cid, token)
          .then((res) => {
            console.log(res.data)
            if (res.data) {
              if (res.data.status) {
                const subtopicName = res.data.message.subTopicName;
                const subtopiclist = [];
                for (let names of subtopicName) {
                  subtopiclist.push({ value: names, label: names });
                }
                setSelectedOptions(subtopiclist);
                setToggleSelectDepartment(false);
                setAddSubTopicName({ ...addSubTopicName, topicName: [] });
              } else {
                setConfirmBox(true);
                setMsg({ success: false, message: res.data.message });
                window.scrollTo(0, 0);
              }
            }
          })
          .catch((err) => console.log(Object.keys(err)));
      } else {
        setConfirmBox(true);
        setMsg({ success: false, message: "Please select subcourse" });
        window.scrollTo(0, 0);
      }
      setTimeout(() => setConfirmBox(false), 5000);
    } else {
      navigate("/login");
    }
  };

  const handleSubQuestionRemove = (index) => {
    const temp = [...practical.subQuestions];
    temp.splice(index, 1);
    setPractical({ ...practical, subQuestions: temp });
  };

  const SortableSelect = SortableContainer(Select);

  const handleSelectTopic = (e) => {
    setPractical({ ...practical, questionTopic: e });
  };

  const handleSubQuestionExamTimeEdit = (index, value) => {
    const temp = [...practical.subQuestions];
    let findSubQuestion = temp[index];
    findSubQuestion.time = value;
    setPractical({ ...practical, subQuestions: temp });
  };
  const handleSubQuestionRemainderEdit = (index, value) => {
    const temp = [...practical.subQuestions];
    let findSubQuestion = temp[index];
    findSubQuestion.remainder = value;
    setPractical({ ...practical, subQuestions: temp });
  };
  const handleSubQuestionDifficultyEdit = (index, value) => {
    const temp = [...practical.subQuestions];
    let findSubQuestion = temp[index];
    findSubQuestion.difficulty = value;
    setPractical({ ...practical, subQuestions: temp });
  };
  const handleSubQuestionDataEdit = (index, value) => {
    const temp = [...practical.subQuestions];
    let findSubQuestion = temp[index];
    findSubQuestion.data = value;
    setPractical({ ...practical, subQuestions: temp });
  };

  const getSubCourseTopicList = (courseId) => {
    CourseService.findSinglesubcourse(courseId)
      .then((res) => {
        if (res.data) {
          const subtopics = res.data[0].subTopicName;
          const subtopiclist = [];
          for (let names of subtopics) {
            subtopiclist.push({ value: names, label: names });
          }
          setSelectedOptions(subtopiclist);
        }
      })
      .catch((ex) => console.log(ex));
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (props?.updateData) {
        getSubCourseTopicList(props.updateData.cid)
      }
      CourseService.getSubCourses()
        .then((res) => {
          if (res.data) {
            setSCnames(res.data);
          }
        })
        .catch((ex) => console.log(ex));
      if (props.updateData?.testcase.length > 0) {
        const options = props.updateData.testcase.map(item => ({
          value: item._id,
          label: item.specName,
        }))
        setSelectedOption(options)
      } else {
        fetchTestcase()
      }
    } else {
      navigate("/login");
    }
  }, []);

  const handleUpdate = async (e) => {
    setLoading(true);
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      if (
        practical.cid &&
        practical.data &&
        practical.type &&
        practical.time &&
        !isNaN(practical.time) &&
        !isNaN(practical.remainder) &&
        practical.remainder
        // practical.difficulty
      ) {
        try {
          const questionType = "Practical";
          const res = await CourseService.editQuestions(
            practical._id,
            questionType,
            practical,
            token
          );
          if (res.data) {
            setConfirmBox(true);
            setMsg({ success: true, message: res.data.message });
            window.scrollTo(0, 0);
            setPractical({
              cid: "",
              type: "",
              subQuestions: [],
              ...initalExamDetails,
            });
            setTimeout(() => setConfirmBox(false), 3000);
            setLoading(false);
            props.updated(true);
          }
        } catch (ex) {
          setConfirmBox(true);
          setMsg({ success: false, message: ex });
        }
      } else {
        setLoading(false);
        window.scrollTo(0, 0);
        setConfirmBox(true);
        setTimeout(() => setConfirmBox(false), 3000);
      }
    }
  };


  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      if (
        practical.cid &&
        practical.data &&
        practical.type &&
        practical.time &&
        !isNaN(practical.time) &&
        !isNaN(practical.remainder) &&
        practical.remainder
        // practical.difficulty
      ) {
        try {
          const questionType = "Practical";
          const res = await CourseService.postQuestion(
            practical.cid,
            questionType,
            practical,
            token
          );
          if (res.data) {
            setConfirmBox(true);
            setMsg({ success: true, message: res.data.msg });
            window.scrollTo(0, 0);
            setPractical({
              cid: "",
              type: "",
              subQuestions: [],
              ...initalExamDetails,
            });
            setSelectedOption([])
            setTimeout(() => setConfirmBox(false), 3000);
            setLoading(false);
          }
        } catch (ex) {
          setConfirmBox(true);
          setMsg({ success: false, message: ex });
        }
      } else {
        setLoading(false);
        window.scrollTo(0, 0);
        setConfirmBox(true);
        setTimeout(() => setConfirmBox(false), 3000);
      }
    }
  };

  // const handleSelectChange = (selectedOption) => {
  //   setSelectedOption(selectedOption);
  //   const data = selectedOption.map(x => x.value)
  //   setPractical((d) => ({ ...d, testcase: data }))
  // };

  const fetchTestcase = async () => {
    await TestCaseService.getSpecs(token).then(res => {
      const options = res.data.map(item => ({
        value: item._id,
        label: item.specName,
      }));
      setTestcaseOption(options)
    })
  }

  // const handleTestToggle = () => {
  //   setTestCaseStatus(!testCaseStatus)
  //   if (testCaseStatus) {
  //     setSelectedOption([])
  //     setTestCaseJson({})
  //   }
  // }

  const getJsonfromList = async () => {
    // if (selectedOption.length > 0) {
    //   let testcaseData = {}
    //   selectedOption.map(x => {
    //     console.log(x)
    //     TestCaseService.getSpecById(x.value, token).then(
    //       async (res) => {
    //         if (res.data) {
    //           testcaseData[res.data.specName] = {
    //             actions: res.data.actions ? await mapactionname(res.data.actions.actions) : [],
    //             evaluate: res.data.evaluate ? await mapactionname(res.data.evaluate?.actions) : [],
    //           }
    //         }
    //       }
    //     )
    //   })
    //   console.log(testcaseData);
    // }
    if (selectedOption.length > 0) {
      TestCaseService.getSpecJsonData({ specData: selectedOption }, token).then((response) => {
        console.log(response.data);
      }).catch((err) => { console.log(err) });
    }
  }

  return (
    <div className="container my-2 bg-white p-4">
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="200" />
            </div>
            <div className="col-12 text-white h4">
              Uploading Training plan...
            </div>
          </div>
        </div>
      )}
      {confirmBox && (
        <div className="formloade">
          <div className="row text-center">
            <div className="col-6 w-100 confirmbox">
              <div
                className={`alert ${msg.success ? "alert-success" : "alert-danger"
                  }`}
              >
                {msg.message}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="form px-lg-5">
        <h5 className="color-dgreen text-uppercase text-center heading">
          Add New Practical
        </h5>
        <form
          onSubmit={handleSubmit}
          className="px-lg-5 py-3"
          method="post"
          id="myform"
        >
          <div className="row">
            <div className="col position-relative mb-3">
              <label htmlFor="cname" className="form-label">
                SubCourse Name
              </label>
              <select
                className="form-select"
                id="scname"
                value={practical.cid}
                name="scname"
                onChange={(e) => {
                  setPractical((d) => ({ ...d, cid: e.target.value }))
                  getSubCourseTopicList(e.target.value)
                }
                }
              >
                <option value="">select subcourse</option>
                {scnames.map((item, index) => {
                  return (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col position-relative mb-3">
              <label htmlFor="cname" className="form-label">
                Exam Type
              </label>
              <select
                className="form-select"
                id="scname"
                value={practical.type}
                name="scname"
                onChange={(e) =>
                  setPractical((d) => ({ ...d, type: e.target.value }))
                }
              >
                <option value="">select type</option>
                <option value="External">External</option>
                <option value="Internal">Internal</option>
              </select>
            </div>
            <div className="col position-relative mb-3">
              {!toggleSelectDepartment ? (
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Select Question Topic
                </label>
              ) : (
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Add topic name
                </label>
              )}
              <div className="row">
                <div
                  className={`col ${!toggleSelectDepartment ? "col-11" : "col-12"
                    }`}
                  style={{ zIndex: "10" }}
                >
                  {!toggleSelectDepartment ? (
                    <SortableSelect
                      axis="xy"
                      // onSortEnd={onSortEnd}
                      distance={4}
                      getHelperDimensions={({ node }) =>
                        node.getBoundingClientRect()
                      }
                      options={selectedOptions}
                      value={practical.questionTopic}
                      onChange={handleSelectTopic}
                      closeMenuOnSelect={true}
                    />
                  ) : (
                    <div className="row">
                      <div className="col col-10">
                        <input
                          value={addSubTopicName.topicName}
                          onChange={(e) => {
                            setAddSubTopicName({
                              ...addSubTopicName,
                              topicName: [e.target.value],
                            });
                          }}
                          type="text"
                          class="form-control mt-1"
                          placeholder="Add sub topic name"
                        />
                      </div>
                      <div className="col col-2">
                        <button
                          type="button"
                          className="btn btn-sm mt-1 ms-1 btn-success"
                          onClick={handleAddTopic}
                        >
                          <VscCheck />
                        </button>
                        <button
                          className="btn btn-sm mt-1 ms-1 btn-light"
                          onClick={() => {
                            setToggleSelectDepartment(!toggleSelectDepartment)
                            setAddSubTopicName({ topicName: [] })

                          }
                          }
                        >
                          <FcCancel />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col col-1">
                  {!toggleSelectDepartment && (
                    <button
                      className="btn btn-primary my-auto btn-small"
                      onClick={() =>
                        setToggleSelectDepartment(!toggleSelectDepartment)
                      }
                    >
                      +
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-4">
              <label htmlFor="cname" className="form-label">
                Exam Time
              </label>
              <input
                className="form-control"
                id="time"
                name="time"
                value={practical.time}
                type="text"
                inputmode="numeric"
                placeholder="Enter Exam Time in Minutes (ex : 15)"
                onChange={(e) =>
                  setPractical((d) => ({ ...d, time: e.target.value }))
                }
              />
            </div>
            <div className="col col-4">
              <label htmlFor="cname" className="form-label">
                Exam Remainder
              </label>
              <input
                className="form-control"
                id="remainder"
                name="remainder"
                type="text"
                inputmode="numeric"
                placeholder="Enter Exam Remainder in Minutes (ex : 5)"
                value={practical.remainder}
                onChange={(e) =>
                  setPractical((d) => ({ ...d, remainder: e.target.value }))
                }
              />
            </div>
            <div className="col col-4">
              <label htmlFor="difficulty" className="form-label">
                Difficulty
              </label>
              <select
                className="form-select"
                id="difficulty"
                value={practical.difficulty}
                name="difficulty"
                onChange={(e) =>
                  setPractical((d) => ({ ...d, difficulty: e.target.value }))
                }
              >
                <option value="">select type</option>
                <option value="1">Easy</option>
                <option value="2">Medium</option>
                <option value="3">Hard</option>
              </select>
            </div>
            <div className="col col-12">
              <label htmlFor="xlsx" className="form-label">
                Add Data
              </label>
              <CkEditor
                data={practical.data}
                onChange={handleChange}
                onBlur={(event, editor) => { }}
                onFocus={(event, editor) => { }}
              />
              {/* <div>{practical.data && practical.data}</div> */}
            </div>
          </div>

          <div className="mb-3">
            {practical.subQuestions.length > 0 &&
              practical.subQuestions.map((item, index) => {
                return (
                  <>
                    <hr />
                    <div className="row">
                      <div className="col col-2">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => handleSubQuestionRemove(index)}
                        >
                          remove
                        </button>
                      </div>
                      <div className="col col-10">
                        <div className="row">
                          <div className="col col-4">
                            <label htmlFor="cname" className="form-label">
                              Exam Time
                            </label>
                            <input
                              className="form-control"
                              id="time"
                              name="time"
                              value={item.time}
                              type="text"
                              inputmode="numeric"
                              placeholder="Enter Exam Time in Minutes (ex : 15)"
                              onChange={(e) =>
                                handleSubQuestionExamTimeEdit(
                                  index,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="col col-4">
                            <label htmlFor="cname" className="form-label">
                              Exam Remainder
                            </label>
                            <input
                              className="form-control"
                              id="remainder"
                              name="remainder"
                              type="text"
                              inputmode="numeric"
                              placeholder="Enter Exam Remainder in Minutes (ex : 5)"
                              value={item.remainder}
                              onChange={(e) =>
                                handleSubQuestionRemainderEdit(
                                  index,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="col col-4">
                            <label htmlFor="difficulty" className="form-label">
                              Difficulty
                            </label>
                            <select
                              className="form-select"
                              id="difficulty"
                              value={item.difficulty}
                              name="difficulty"
                              onChange={(e) =>
                                handleSubQuestionDifficultyEdit(
                                  index,
                                  e.target.value
                                )
                              }
                            >
                              <option value="">select type</option>
                              <option value="1">Easy</option>
                              <option value="2">Medium</option>
                              <option value="3">Hard</option>
                            </select>
                          </div>
                          <div className="col col-12">
                            <label htmlFor="xlsx" className="form-label">
                              Add Data
                            </label>
                            <CkEditor
                              data={item.data}
                              onChange={(e, editor) =>
                                handleSubQuestionDataEdit(
                                  index,
                                  editor.getData()
                                )
                              }
                              onBlur={(event, editor) => { }}
                              onFocus={(event, editor) => { }}
                            />
                            {/* <div>{item.data && item.data}</div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <hr /> */}
                  </>
                );
              })}

            <button
              type="button"
              className="btn btn-warning btn-sm text-white my-2"
              onClick={() => {
                const temp = [...practical.subQuestions];
                temp.push({ ...initalExamDetails });
                setPractical({
                  ...practical,
                  subQuestions: temp,
                });
              }}
            >
              Add sub question
            </button>
          </div>
          {/* <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={() => handleTestToggle()} checked={testCaseStatus} />
            <label class="form-check-label" for="flexSwitchCheckDefault">Are you want to add testCase?</label>
          </div> */}
          {/* {testCaseStatus && <> */}
          <br />
          {/* <div className="my-2">
            <h5>Select Appropriate Tescases Actions And Evaluate</h5>
            <div className="row my-2">
              <div className="col-8 my-2">
                <label>Select TestCases for UI</label>
                <Select
                  options={testCaseOption}
                  value={selectedOption}
                  onChange={handleSelectChange}
                  isMulti
                />
              </div>
            </div>
          </div> */}
          <div className="my-2">
            <div> Select TestCases for JSON</div>
            <textarea cols="135" value={practical.testCaseJson} onChange={(e) => setPractical({ ...practical, testCaseJson: e.target.value })} rows="10" />
          </div> <br />

          <div className="my-4">
            <button
              type="button"
              onClick={(e) =>
                props.updateData ? handleUpdate(e) : handleSubmit(e)
              }
              className={`btn  ${props.updateData ? "btn-success" : "btn-primary"
                }`}
            >
              {props.updateData ? "Update Question" : "Add Question"}
            </button>
            <button
              type="reset"
              className="btn btn-secondary ms-3"
              onClick={() => {
                window.scrollTo(0, 0);
                setPractical({
                  cid: "",
                  type: "",
                  subQuestions: [],
                  ...initalExamDetails,
                });
              }}
            >
              Reset
            </button>
          </div>
        </form>
      </div>


    </div >
  );
}

import React, { useState, useEffect } from "react";
import CourseService from "../../services/CourseService";
import formloader from "../../images/formloading.svg";
import Select from "react-select";
import { SortableContainer } from "react-sortable-hoc";
import { VscCheck } from "react-icons/vsc";
import { FcCancel } from "react-icons/fc";
import XLSX from "xlsx";
import CkEditor from "./CkEditor";
import { useNavigate } from "react-router-dom";

export default function AddTrininigPlan(props) {
  const [trainingplan, setTrainingplan] = useState({
    cid: "",
    examType: "",
    questionType: "",
    optionType: "",
    Question: "",
    Option: [],
    Answer: [],
    questionWeightage: "1",
    Type: "",
    difficulty: "",
    questionTopic: "",
    xlsx: "",
  });
  const [scnames, setSCnames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addData, setVal] = useState("");
  const [addDataA, setAddDataA] = useState("");
  const [addDataB, setAddDataB] = useState("");
  const [addDataC, setAddDataC] = useState("");
  const [addDataD, setAddDataD] = useState("");
  const [answerArray, setAnswerArray] = useState([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [validate, setValidate] = useState(false);
  const [optionValid, setOptionValid] = useState([]);
  const [answerValid, setAnswerValid] = useState([]);
  const [questionValid, setQuestionValid] = useState([]);
  const [typeValid, setTypeValid] = useState([]);
  const [subTopicValid, setSubTopicValid] = useState([]);
  const [difficultyValid, setDifficultyValid] = useState([]);
  const [weightageValid, setWeightageValid] = useState([]);
  const [msg, setMsg] = useState({ success: false, message: "" });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [addSubTopicName, setAddSubTopicName] = useState({ topicName: [] });
  const [toggleSelectDepartment, setToggleSelectDepartment] = useState(false);
  const navigate = useNavigate();
  const css = `
    .ck-editor__editable {
        min-height: 100px !important;
    }
  `;

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setTrainingplan((d) => ({ ...d, Question: data }));
  };

  const SortableSelect = SortableContainer(Select);

  const handleAddTopic = () => {
    // e.preventDefault();
    const token = localStorage.getItem("token");
    if (token) {
      if (trainingplan.cid) {
        CourseService.addSubTopicName(addSubTopicName, trainingplan.cid, token)
          .then((res) => {
            if (res.data) {
              if (res.data.status) {
                const subtopicName = res.data.message.subTopicName;
                const subtopiclist = [];
                for (let names of subtopicName) {
                  subtopiclist.push({ value: names, label: names });
                }
                setSelectedOptions(subtopiclist);
                setToggleSelectDepartment(false);
                setAddSubTopicName({ ...addSubTopicName, topicName: [] });
              } else {
                setConfirmBox(true);
                setMsg({ success: false, message: res.data.msg });
                window.scrollTo(0, 0);
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        setConfirmBox(true);
        setMsg({ success: false, message: "Please select subcourse" });
        window.scrollTo(0, 0);
      }

      setTimeout(() => setConfirmBox(false), 5000);
    } else {
      navigate("/login");
    }
  };

  const handleSelectTopic = (e) => {
    setTrainingplan({ ...trainingplan, questionTopic: e });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      CourseService.getSubCourses()
        .then((res) => {
          if (res.data) {
            setSCnames(res.data);
          }
        })
        .catch((ex) => console.log(ex));
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (trainingplan.cid) {
        CourseService.findSinglesubcourse(trainingplan.cid)
          .then((res) => {
            if (res.data) {
              const subtopics = res.data[0].subTopicName;
              const subtopiclist = [];
              for (let names of subtopics) {
                subtopiclist.push({ value: names, label: names });
              }
              setSelectedOptions(subtopiclist);
            }
          })
          .catch((ex) => console.log(ex));
      }
    } else {
      navigate("/login");
    }
  }, [trainingplan.cid]);

  const handleChangeA = (e, editor) => {
    const dataA = editor.getData();
    const options = trainingplan.Option.findIndex((item) => item.Id === "A");
    if (options > -1) {
      const newArray = [...trainingplan.Option];
      newArray[options].Value = dataA;
      setTrainingplan((d) => ({ ...d, Option: newArray }));
    } else {
      const optionA = {
        Id: "A",
        Value: dataA,
      };
      trainingplan.Option.push(optionA);
    }
  };
  const handleChangeB = (e, editor) => {
    const dataB = editor.getData();
    const options = trainingplan.Option.findIndex((item) => item.Id === "B");
    if (options > -1) {
      const newArray = [...trainingplan.Option];
      newArray[options].Value = dataB;
      setTrainingplan((d) => ({ ...d, Option: newArray }));
    } else {
      const optionB = {
        Id: "B",
        Value: dataB,
      };
      trainingplan.Option.push(optionB);
    }
  };
  const handleChangeC = (e, editor) => {
    const dataC = editor.getData();
    const options = trainingplan.Option.findIndex((item) => item.Id === "C");
    if (options > -1) {
      const newArray = [...trainingplan.Option];
      newArray[options].Value = dataC;
      setTrainingplan((d) => ({ ...d, Option: newArray }));
    } else {
      const optionC = {
        Id: "C",
        Value: dataC,
      };
      trainingplan.Option.push(optionC);
    }
  };
  const handleChangeD = (e, editor) => {
    const dataD = editor.getData();
    const options = trainingplan.Option.findIndex((item) => item.Id === "D");
    if (options > -1) {
      const newArray = [...trainingplan.Option];
      newArray[options].Value = dataD;
      setTrainingplan((d) => ({ ...d, Option: newArray }));
    } else {
      const optionD = {
        Id: "D",
        Value: dataD,
      };
      trainingplan.Option.push(optionD);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      if (trainingplan.questionType == "XLSX Format") {
        // setLoading(true);
        // set up FileReader
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = async (e) => {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary", bookVBA: true });
          // get worksheet
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = await XLSX.utils.sheet_to_json(ws);
          var AllQuestions = [];
          // Insert to db
          if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              if (data[i]) {
                const Question = data[i].Question;
                <p name="editor1">{Question}</p>;
                const difficulty = data[i].Difficulty;
                let Option;
                if (
                  data[i].A === true ||
                  data[i].A === false ||
                  data[i].B === false ||
                  data[i].B === true
                ) {
                  Option = [
                    { Id: "A", Value: `${data[i].A}` },
                    { Id: "B", Value: `${data[i].B}` },
                  ];
                } else if (isNaN(data[i].A)) {
                  if (
                    data[i].A.toString().toLowerCase() === "yes" ||
                    data[i].A.toString().toLowerCase() === "no" ||
                    data[i].B.toString().toLowerCase() === "yes" ||
                    data[i].B.toString().toLowerCase() === "no"
                  ) {
                    Option = [
                      { Id: "A", Value: `${data[i].A}` },
                      { Id: "B", Value: `${data[i].B}` },
                    ];
                  } else {
                    Option = [
                      { Id: "A", Value: `${data[i].A}` },
                      { Id: "B", Value: `${data[i].B}` },
                      { Id: "C", Value: `${data[i].C}` },
                      { Id: "D", Value: `${data[i].D}` },
                    ];
                  }
                } else {
                  Option = [
                    { Id: "A", Value: `${data[i].A}` },
                    { Id: "B", Value: `${data[i].B}` },
                    { Id: "C", Value: `${data[i].C}` },
                    { Id: "D", Value: `${data[i].D}` },
                  ];
                }

                let Answer = [];
                if (data[i].Type == 0) {
                  Answer = Option.filter((op) => {
                    if (op.Id === data[i].Answer) {
                      return op;
                    }
                  });
                }
                var abc = [];
                if (data[i].Type === 1 && data[i].Answer) {
                  data[i].Answer = data[i].Answer.split(/[, ]+/).filter(
                    (element) => element
                  );
                  for (let j = 0; j < data[i].Answer.length; j++) {
                    abc[j] = Option.find((op) => op.Id === data[i].Answer[j]);
                    // }
                  }
                  Answer = abc;
                }
                const Type = data[i].Type;
                const examType = trainingplan.examType;
                const questionWeightage = data[i].Weightage;
                const questionTopic = {
                  value:
                    data[i].SubTopicName && isNaN(data[i].SubTopicName)
                      ? data[i].SubTopicName.toLowerCase()
                      : "",
                };
                const OneQuestion = [
                  {
                    Question,
                    Option,
                    Answer,
                    Type,
                    examType,
                    questionWeightage,
                    difficulty,
                    questionTopic,
                  },
                ];
                AllQuestions.push(OneQuestion[0]);
              }
            }
            for (let questions of AllQuestions) {
              if (questions.Question) {
                questionValid.push(false);
              } else {
                questionValid.push(true);
              }
              if (questions.Option && questions.Option.length > 0) {
                if (
                  questions.Option[0].Value === true ||
                  questions.Option[1].Value === false ||
                  questions.Option[1].Value === true ||
                  questions.Option[0].Value === false
                ) {
                  optionValid.push(false);
                } else if (isNaN(questions.Option[0].Value)) {
                  if (
                    questions.Option[0].Value.toLowerCase() === "yes" ||
                    questions.Option[1].Value.toLowerCase() === "yes" ||
                    questions.Option[1].Value.toLowerCase() === "no" ||
                    questions.Option[0].Value.toLowerCase() === "no"
                  ) {
                    optionValid.push(false);
                  }
                } else {
                  questions.Option.forEach((obj) => {
                    if (obj.Value !== "undefined") {
                      optionValid.push(false);
                    } else {
                      optionValid.push(true);
                    }
                  });
                }
              } else {
                optionValid.push(true);
              }
              if (questions.Answer && questions.Answer.length > 0) {
                questions.Answer.forEach((obj) => {
                  if (obj.Value !== "undefined") {
                    answerValid.push(false);
                  } else {
                    answerValid.push(true);
                  }
                });
              } else {
                answerValid.push(true);
              }
              if (questions.Type) {
                if (isNaN(questions.Type) === true) {
                  typeValid.push(true);
                } else {
                  typeValid.push(false);
                }
              } else {
                if (questions.Type === 0) {
                  typeValid.push(false);
                } else {
                  typeValid.push(true);
                }
              }
              if (questions.questionWeightage) {
                if (isNaN(questions.questionWeightage) === true) {
                  weightageValid.push(true);
                } else {
                  weightageValid.push(false);
                }
              }
              if (questions.difficulty) {
                const currDifficulty = parseInt(questions.difficulty);
                if (
                  currDifficulty === 1 ||
                  currDifficulty === 2 ||
                  currDifficulty === 3
                ) {
                  difficultyValid.push(false);
                } else {
                  difficultyValid.push(true);
                }
              }
              if (questions.questionTopic) {
                if (typeof questions?.questionTopic.value === "string") {
                  addSubTopicName.topicName.push(
                    questions.questionTopic.value.toLowerCase()
                  );
                  subTopicValid.push(false);
                } else {
                  setAddSubTopicName({ topicName: [] });
                  subTopicValid.push(true);
                }
              }
            }
            if (
              trainingplan.cid &&
              data.length > 0 &&
              trainingplan.examType &&
              !questionValid.includes(true) &&
              !optionValid.includes(true) &&
              !answerValid.includes(true) &&
              !typeValid.includes(true) &&
              !weightageValid.includes(true) &&
              !difficultyValid.includes(true) &&
              !subTopicValid.includes(true)
            ) {
              handleAddTopic();
              try {
                const questionType = "MCQ";
                const res = await CourseService.postQuestion(
                  trainingplan.cid,
                  questionType,
                  AllQuestions,
                  token
                );
                if (res.data) {
                  setConfirmBox(true);
                  setMsg({ success: true, message: res.data.msg });
                  window.scrollTo(0, 0);
                  setTrainingplan({
                    ...trainingplan,
                    questionType: "",
                    Question: "",
                    optionType: "",
                    Option: [],
                    Answer: [],
                    questionWeightage: "",
                    Type: "",
                    difficulty: "",
                    xlsx: "",
                  });
                  setVal("");
                  setAddDataA("");
                  setAddDataB("");
                  setAddDataC("");
                  setAddDataD("");
                  setAnswerArray([]);
                  setOptionValid([]);
                  setAnswerValid([]);
                  setQuestionValid([]);
                  setTypeValid([]);
                  setDifficultyValid([]);
                  setWeightageValid([]);
                  setSubTopicValid([]);
                  setLoading(false);
                  setTimeout(() => setConfirmBox(false), 5000);
                }
              } catch (ex) {
                setConfirmBox(true);
                setMsg({ success: false, message: ex });
              }
            } else {
              setMsg({
                message: `Please verify your ${trainingplan.cid === "" ? "SubCourse Name, " : ""
                  } ${questionValid.includes(true) === "" ? "Question,  " : ""} ${typeValid.includes(true) === "" ? "Type,  " : ""
                  }${trainingplan.examType === "" ? "Exam Type,  " : ""}
                ${data.length === 0 ? "XLSX file,  " : ""}
                ${weightageValid.includes(true) === ""
                    ? "Question Weightage,  "
                    : ""
                  } ${difficultyValid.includes(true) ? "Question difficulty,  " : ""
                  }${subTopicValid.includes(true)
                    ? "Question Subtopic name as a string,  "
                    : ""
                  } ${optionValid.includes(true) ? "Option,  " : ""}
                 ${answerValid.includes(true) ? "Answer,  " : ""}
                field`,
                success: false,
              });
              window.scrollTo(0, 0);
              setConfirmBox(true);
              setTimeout(() => setConfirmBox(false), 5000);
              setAnswerArray([]);
              setOptionValid([]);
              setAnswerValid([]);
              setQuestionValid([]);
              setTypeValid([]);
              setWeightageValid([]);
            }
          }
        };
        if (rABS) {
          reader.readAsBinaryString(trainingplan.xlsx);
        }
      } else {
        if (trainingplan.optionType === "True and False Type") {
          if (
            (trainingplan.Option[0].Value.replace(/<[^>]*>/g, "")
              .replace(/&nbsp;/gi, "")
              .toLowerCase() === "true" ||
              trainingplan.Option[0].Value.replace(/<[^>]*>/g, "")
                .replace(/&nbsp;/gi, "")
                .toLowerCase() === "false" ||
              trainingplan.Option[0].Value.replace(/<[^>]*>/g, "")
                .replace(/&nbsp;/gi, "")
                .toLowerCase() === "yes" ||
              trainingplan.Option[0].Value.replace(/<[^>]*>/g, "")
                .replace(/&nbsp;/gi, "")
                .toLowerCase() === "no") &&
            (trainingplan.Option[1].Value.replace(/<[^>]*>/g, "")
              .replace(/&nbsp;/gi, "")
              .toLowerCase() === "true" ||
              trainingplan.Option[1].Value.replace(/<[^>]*>/g, "")
                .replace(/&nbsp;/gi, "")
                .toLowerCase() === "false" ||
              trainingplan.Option[1].Value.replace(/<[^>]*>/g, "")
                .replace(/&nbsp;/gi, "")
                .toLowerCase() === "yes" ||
              trainingplan.Option[1].Value.replace(/<[^>]*>/g, "")
                .replace(/&nbsp;/gi, "")
                .toLowerCase() === "no")
          ) {
            optionValid.push(false);
          } else {
            optionValid.push(true);
          }
        } else {
          trainingplan.Option.forEach((obj) => {
            if (obj.Value !== "") {
              optionValid.push(false);
            } else {
              optionValid.push(true);
            }
          });
        }
        trainingplan.Answer.forEach((obj) => {
          if (obj.Value !== "") {
            answerValid.push(false);
          } else {
            answerValid.push(true);
          }
        });
        if (
          trainingplan.cid !== "" &&
          trainingplan.examType !== "" &&
          trainingplan.questionType !== "" &&
          trainingplan.Question !== "" &&
          ((trainingplan.Type == "0" && trainingplan.Answer.length === 1) ||
            (trainingplan.Type == "1" && trainingplan.Answer.length > 1)) &&
          trainingplan.questionWeightage !== "" &&
          !isNaN(trainingplan.questionWeightage) &&
          !optionValid.includes(true) &&
          !answerValid.includes(true) &&
          trainingplan.Answer.length > 0 &&
          trainingplan.Option.length > 0 &&
          trainingplan.difficulty !== ""
        ) {
          try {
            const AllQuestions = [trainingplan];
            const questionType = "MCQ";
            const res = await CourseService.postQuestion(
              trainingplan.cid,
              questionType,
              AllQuestions,
              token
            );
            if (res.data) {
              setConfirmBox(true);
              setMsg({ success: true, message: res.data.msg });
              window.scrollTo(0, 0);
              setTrainingplan({
                ...trainingplan,
                questionType: "",
                Question: "",
                optionType: "",
                Option: [],
                Answer: [],
                questionWeightage: "",
                Type: "",
                difficulty: "",
                xlsx: "",
              });
              setVal("");
              setAddDataA("");
              setAddDataB("");
              setAddDataC("");
              setAddDataD("");
              setAnswerArray([]);
              setOptionValid([]);
              setAnswerValid([]);
              setQuestionValid([]);
              setTypeValid([]);
              setWeightageValid([]);
              setLoading(false);
              setTimeout(() => setConfirmBox(false), 5000);
            }
          } catch (ex) {
            setConfirmBox(true);
            setMsg({ success: false, message: ex });
          }
        } else {
          setMsg({
            message: `Please verify your  ${trainingplan.questionType === "" ? "Add Question Type,  " : ""
              }${trainingplan.cid === "" ? "SubCourse Name, " : ""}${trainingplan.Answer.length === 0 ? "Checked Answer, " : ""
              }${trainingplan.Option.length === 0 ? "Option, " : ""}
             ${trainingplan.Type == "0" && trainingplan.Answer.length > 1
                ? "Type Radio to set answer length only 1, "
                : ""
              }${trainingplan.Type == "1" && trainingplan.Answer.length < 2
                ? "Type checkbox set mininmum answer length 2, "
                : ""
              } ${trainingplan.Question === "" ? "Question,  " : ""}
            ${trainingplan.Type === "" ? "Type,  " : ""}${trainingplan.examType === "" ? "Exam Type,  " : ""
              }
          ${optionValid.includes(true) ? "Option,  " : ""}
          ${answerValid.includes(true) ? "Answer,  " : ""}
          ${trainingplan.questionWeightage === "" ? "Question Weightage,  " : ""
              }${isNaN(trainingplan.questionWeightage)
                ? "Question Weightage value enter only numeric value ,  "
                : ""
              }${trainingplan.difficulty === "" ? "Difficulty, " : ""}
           field`,
            success: false,
          });
          window.scrollTo(0, 0);
          setConfirmBox(true);
          setTimeout(() => setConfirmBox(false), 5000);
          setOptionValid([]);
          setAnswerValid([]);
          setQuestionValid([]);
          setTypeValid([]);
          setWeightageValid([]);
        }
      }
    }
  };

  const handleCheckbox = (e, id) => {
    if (e.target.checked) {
      const findOption = trainingplan.Option.find((item) => item.Id === id);
      const findIndex = trainingplan.Answer.findIndex((item) => item.Id === id);
      if (findOption && findIndex === -1) {
        trainingplan.Answer.push(findOption);
        setAnswerArray([...answerArray, id]);
      }
    } else {
      const index = trainingplan.Answer.findIndex((object) => {
        return object.Id === id;
      });
      const optionindex = answerArray.findIndex((object) => {
        return object === id;
      });
      answerArray.splice(optionindex, 1);
      let newArr = trainingplan.Answer;
      newArr.splice(index, 1);
      setTrainingplan({
        ...trainingplan,
        Answer: newArr,
      });
      setAnswerArray(answerArray);
    }
  };

  return (
    <div className="container my-2 bg-white p-4">
      <style>{css}</style>
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="100" />
            </div>
            <div className="col-12 text-white h4">
              Uploading Training plan...
            </div>
          </div>
        </div>
      )}
      {confirmBox && (
        <div className="formloade">
          <div className="row text-center">
            <div className="col-6 w-100 confirmbox">
              <div
                className={`alert ${msg.success ? "alert-success" : "alert-danger"
                  }`}
              >
                {msg.message}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="form px-lg-5">
        <h5 className="color-dgreen text-uppercase text-center heading">
          Add New Questions
        </h5>
        <form
          onSubmit={handleSubmit}
          className="px-lg-5 py-3"
          method="post"
          id="myform"
        >
          <div className="col position-relative mb-3">
            <label htmlFor="cname" className="form-label">
              SubCourse Name
            </label>
            <select
              className={
                trainingplan.cid || !validate
                  ? "form-select"
                  : "form-select border-danger"
              }
              id="scname"
              name="scname"
              value={trainingplan.cid}
              onChange={(e) =>
                setTrainingplan((d) => ({ ...d, cid: e.target.value }))
              }
            >
              <option value="">select subcourse</option>
              {scnames.map((item, index) => {
                return (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col position-relative mb-3">
            <label htmlFor="cname" className="form-label">
              Exam Type
            </label>
            <select
              className={
                trainingplan.examType || !validate
                  ? "form-select"
                  : "form-select border-danger"
              }
              id="scname"
              name="scname"
              value={trainingplan.examType}
              onChange={(e) =>
                setTrainingplan((d) => ({ ...d, examType: e.target.value }))
              }
            >
              <option value="">select type</option>
              <option value="External">External</option>
              <option value="Internal">Internal</option>
            </select>
          </div>
          <div className="col position-relative mb-3">
            <label htmlFor="cname" className="form-label me-3">
              Please Select Question Add Type :
            </label>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                checked={trainingplan.questionType === "Single Question"}
                value="Single Question"
                onClick={() =>
                  setTrainingplan((d) => ({
                    ...d,
                    questionType: "Single Question",
                  }))
                }
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                Single Question
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                checked={trainingplan.questionType === "XLSX Format"}
                value="XLSX Format"
                onClick={() =>
                  setTrainingplan((d) => ({
                    ...d,
                    questionType: "XLSX Format",
                  }))
                }
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                XLSX Format
              </label>
            </div>
          </div>

          {trainingplan.questionType === "XLSX Format" && (
            <>
              <div className="mb-3">
                <label htmlFor="xlsx" className="form-label">
                  Select Xlsx file
                </label>
                <input
                  type="file"
                  name="xlsx"
                  id="xlsx"
                  className="form-control"
                  required
                  onChange={(e) =>
                    setTrainingplan((d) => ({ ...d, xlsx: e.target.files[0] }))
                  }
                />
              </div>
              <div className="mb-5">
                <a href={`/QuestionBank.xlsx`} target="_blank">
                  Click here to Download demo QuestionBank Excelsheet.
                </a>
              </div>
            </>
          )}
          {trainingplan.questionType === "Single Question" && (
            <>
              <div className="col position-relative mb-3">
                <label htmlFor="cname" className="form-label me-3">
                  Please Question Option Format Type :
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={trainingplan.optionType === "True and False Type"}
                    value="True and False Type"
                    onClick={() =>
                      setTrainingplan((d) => ({
                        ...d,
                        optionType: "True and False Type",
                      }))
                    }
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Boolean or yes , no Type
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={trainingplan.optionType === "Four Option Type"}
                    value="Four Option Type"
                    onClick={() =>
                      setTrainingplan((d) => ({
                        ...d,
                        optionType: "Four Option Type",
                      }))
                    }
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    Four Option Type
                  </label>
                </div>
              </div>
              <div className="col position-relative mb-3">
                <label htmlFor="cname" className="form-label">
                  Type
                </label>
                <select
                  className={
                    trainingplan.Type || !validate
                      ? "form-select"
                      : "form-select border-danger"
                  }
                  id="type"
                  name="type"
                  onChange={(e) =>
                    setTrainingplan((d) => ({ ...d, Type: e.target.value }))
                  }
                >
                  <option value="">select type</option>
                  <option value="0">Radio</option>
                  {trainingplan.optionType === "Four Option Type" && (
                    <option value="1">Checkbox</option>
                  )}
                </select>
              </div>
              <div className="col position-relative mb-3">
                <label htmlFor="difficulty" className="form-label">
                  Difficulty
                </label>
                <select
                  className="form-select"
                  id="difficulty"
                  value={trainingplan.difficulty}
                  name="difficulty"
                  onChange={(e) =>
                    setTrainingplan((d) => ({
                      ...d,
                      difficulty: e.target.value,
                    }))
                  }
                >
                  <option value="">select type</option>
                  <option value="1">Easy</option>
                  <option value="2">Medium</option>
                  <option value="3">Hard</option>
                </select>
              </div>
              <div className="col position-relative mb-3">
                {!toggleSelectDepartment ? (
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Select Question Topic
                  </label>
                ) : (
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Add topic name
                  </label>
                )}
                <div className="row">
                  <div
                    className={`col ${!toggleSelectDepartment ? "col-11" : "col-12"
                      }`}
                    style={{ zIndex: "10" }}
                  >
                    {!toggleSelectDepartment ? (
                      <SortableSelect
                        axis="xy"
                        // onSortEnd={onSortEnd}
                        distance={4}
                        getHelperDimensions={({ node }) =>
                          node.getBoundingClientRect()
                        }
                        options={selectedOptions}
                        value={trainingplan.questionTopic}
                        onChange={handleSelectTopic}
                        closeMenuOnSelect={true}
                      />
                    ) : (
                      <div className="row">
                        <div className="col col-10">
                          <input
                            value={addSubTopicName.topicName}
                            onChange={(e) => {
                              setAddSubTopicName({
                                ...addSubTopicName,
                                topicName: [e.target.value],
                              });
                            }}
                            type="text"
                            class="form-control mt-1"
                            placeholder="Add sub topic name"
                          />
                        </div>
                        <div className="col col-2">
                          <button
                            type="button"
                            className="btn btn-sm mt-1 ms-1 btn-success"
                            onClick={handleAddTopic}
                          >
                            <VscCheck />
                          </button>
                          <button
                            className="btn btn-sm mt-1 ms-1 btn-light"
                            onClick={() => {
                              setToggleSelectDepartment(!toggleSelectDepartment)
                              setAddSubTopicName({ topicName: [] })
                            }
                            }
                          >
                            <FcCancel />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col col-1">
                    {!toggleSelectDepartment && (
                      <button
                        className="btn btn-primary my-auto btn-small"
                        onClick={() =>
                          setToggleSelectDepartment(!toggleSelectDepartment)
                        }
                      >
                        +
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col position-relative mb-3">
                <label htmlFor="cname" className="form-label">
                  Question Weightage
                </label>
                <input
                  className="form-control"
                  name="questionWeightage"
                  type="text"
                  inputmode="numeric"
                  placeholder="Enter question marks (ex : 5,10,15)"
                  value={trainingplan.questionWeightage}
                  onChange={(e) =>
                    setTrainingplan((d) => ({
                      ...d,
                      questionWeightage: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="mb-3">
                <label htmlFor="xlsx" className="form-label">
                  Add Question
                </label>
                <div className="App">
                  <CkEditor
                    data={addData}
                    onChange={handleChange}
                    onBlur={(event, editor) => {
                      // console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log("Focus.", editor);
                    }}
                  />
                </div>
                <div>{trainingplan.Question && trainingplan.Question}</div>
              </div>
              {trainingplan.optionType === "Four Option Type" && (
                <div className="mt-3">
                  <label htmlFor="xlsx" className="form-label">
                    Question Option
                  </label>
                  <div className="App">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="hobbies"
                        checked={answerArray.includes("A")}
                        onClick={(e) => handleCheckbox(e, "A")}
                      />
                      <label className="form-check-label">
                        This checkbox cheked to set answer
                      </label>
                    </div>
                    <CkEditor
                      data={addDataA}
                      onChange={handleChangeA}
                      onBlur={(event, editor) => {
                        // console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        // console.log("Focus.", editor);
                      }}
                    />
                  </div>
                  <div className="App mt-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="hobbies"
                        checked={answerArray.includes("B")}
                        onClick={(e) => handleCheckbox(e, "B")}
                      />
                      <label className="form-check-label">
                        This checkbox cheked to set answer
                      </label>
                    </div>
                    <CkEditor
                      data={addDataB}
                      onChange={handleChangeB}
                      onBlur={(event, editor) => {
                        // console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        // console.log("Focus.", editor);
                      }}
                    />
                  </div>
                  <div className="App mt-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="hobbies"
                        checked={answerArray.includes("C")}
                        onClick={(e) => handleCheckbox(e, "C")}
                      />
                      <label className="form-check-label">
                        This checkbox cheked to set answer
                      </label>
                    </div>
                    <CkEditor
                      data={addDataC}
                      onChange={handleChangeC}
                      onBlur={(event, editor) => {
                        // console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        // console.log("Focus.", editor);
                      }}
                    />
                  </div>
                  <div className="App mt-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="hobbies"
                        checked={answerArray.includes("D")}
                        onClick={(e) => handleCheckbox(e, "D")}
                      />
                      <label className="form-check-label">
                        This checkbox cheked to set answer
                      </label>
                    </div>
                    <CkEditor
                      data={addDataD}
                      onChange={handleChangeD}
                      onBlur={(event, editor) => {
                        // console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        // console.log("Focus.", editor);
                      }}
                    />
                  </div>
                </div>
              )}
              {trainingplan.optionType === "True and False Type" && (
                <div className="mt-3">
                  <label htmlFor="xlsx" className="form-label">
                    Question Option
                  </label>
                  <div className="App">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="hobbies"
                        checked={answerArray.includes("A")}
                        onClick={(e) => handleCheckbox(e, "A")}
                      />
                      <label className="form-check-label">
                        This checkbox cheked to set answer
                      </label>
                    </div>
                    <CkEditor
                      data={addDataA}
                      onChange={handleChangeA}
                      onBlur={(event, editor) => {
                        // console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        // console.log("Focus.", editor);
                      }}
                    />
                  </div>
                  <div className="App mt-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="hobbies"
                        checked={answerArray.includes("B")}
                        onClick={(e) => handleCheckbox(e, "B")}
                      />
                      <label className="form-check-label">
                        This checkbox cheked to set answer
                      </label>
                    </div>
                    <CkEditor
                      data={addDataB}
                      onChange={handleChangeB}
                      onBlur={(event, editor) => {
                        // console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        // console.log("Focus.", editor);
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}

          <button type="submit" className="btn btn-submit mt-2">
            Add Questions
          </button>
          <button
            type="reset"
            onClick={(e) => {
              setTrainingplan({
                cid: "",
                examType: "",
                questionType: "",
                Question: "",
                Option: [],
                Answer: [],
                questionWeightage: "",
                Type: "",
                xlsx: "",
                difficulty: "",
              });
              setToggleSelectDepartment(false);
              setAddDataA("");
              setAddDataB("");
              setAddDataC("");
              setAddDataD("");
              setVal("");
              setAnswerArray([]);
              setOptionValid([]);
              setAnswerValid([]);
              setQuestionValid([]);
              setTypeValid([]);
              setWeightageValid([]);

              window.scrollTo(0, 0);
            }}
            className="btn btn-secondary ms-3"
          >
            Reset
          </button>
        </form>
      </div>
    </div>
  );
}

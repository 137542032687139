import $ from "jquery";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BiCommentDetail } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import MyContinueCard from "../../components/myLearningCard/myCompletedCard";
import MyLearningCard from "../../components/myLearningCard/myLearningCard";
import formloader from "../../images/formloading.svg";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import global from "../../servicesvariable";
import { handleContinue } from "../../utils/course/handleContinue";
import { handleImageLoadingError } from '../../utils/image/handleImageLoadingError';
import "./MyLearning.scss";


export default function MyLearning(props) {
  const [continueLearning, setContinueLearning] = useState([]);
  const [tcounter, setTcounter] = useState([]);
  const [subcourses, setSubCourses] = useState([]);
  const [user, setUser] = useState([]);
  const [userResult, setUserResult] = useState([]);
  const [hostId, setHostId] = useState("");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  $(window).scroll(function (e) {
    var $el = $(".learningTab");
    if ($(this).scrollTop() > $("header").height()) {
      $el.css({ top: $("header").height() });
      $el.addClass("position-sticky");
    } else {
      $el.removeClass("position-sticky");
    }
  });

  const getStudentColleagueProgressedCourses = async (token, userId) => {
    try {
      setLoading(true);
      const limit = "undefined";
      const includeNotStarted = 1;
      let includeCompleted = 1;
      const progressedCourses =
        await CourseService.getStudentColleagueProgressedCourses(
          token,
          userId,
          limit,
          includeNotStarted,
          includeCompleted
        );
      let courses = progressedCourses.data.data;
      setContinueLearning(courses);
      for (let i = 0; i < courses.length; i++) {
        const tplan = await CourseService.getTrainingPlan(
          courses[i].courseId.name
        );
        if (tplan.data && courses[i].completedTrainingDayPlan) {
          let length = {
            length:
              (tplan.data.length - courses[i].completedTrainingDayPlan.length) *
              8.5,
            name: courses[i].courseId.name,
          };
          setTcounter((d) => [...d, length]);
        } else {
          let length = {
            length: tplan.data.length * 8.5,
            name: courses[i].courseId.name,
          };
          setTcounter((d) => [...d, length]);
        }
      }
      setLoading(false);

    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const getSubCourse = (subCourseName) => {

    return subcourses.find(subCourse => subCourse.name === subCourseName) ?? {};
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    var counter = 0;

    if (token) {
      const decoded = jwt_decode(token);
      const userId = jwt_decode(localStorage.getItem("token")).id;
      CourseService.getSingleUserResult(token, userId).then((res) => {
        if (res.data == "result not found") {
          setUserResult([]);
        } else {
          setUserResult(res.data);
        }
      });
      LoginService.getSingleUser(decoded.id, token)
        .then((res) => {
          if (res.data) {
            setHostId(res.data.hostId);
          }
        })
        .catch((ex) => { });

      CourseService.getSubCourses()
        .then((res) => {
          if (res.data) {
            setSubCourses(res.data);
          }
        })
        .catch((ex) => { });
      LoginService.getSingleUserById(decoded.id, token)
        .then((res) => {
          if (res.data) {
            setUser(res.data.courses);
          }
        })
        .catch((ex) => { });
      getStudentColleagueProgressedCourses(token, userId);
    }
  }, []);

  return (
    <>
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="100" />
            </div>
            <div className="col-12 text-white h4">Loading...</div>
          </div>
        </div>
      )}
      <section class="container pb-0">
        <ul class="nav nav-tabs pl-110 learningTab " id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active py-3"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              In Progress
            </button>
          </li>
          <li class="nav-item " role="presentation">
            <button
              class="nav-link py-3"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              Completed
            </button>
          </li>
          <li class="nav-item " role="presentation">
            <button
              class="nav-link py-3"
              id="result-tab"
              data-bs-toggle="tab"
              data-bs-target="#result"
              type="button"
              role="tab"
              aria-controls="result"
              aria-selected="false"
            >
              Result
            </button>
          </li>
          {/* <li class="nav-item " role="presentation">
          <button
            class="nav-link py-3"
            id="userprofile-tab"
            data-bs-toggle="tab"
            data-bs-target="#userprofile"
            type="button"
            role="tab"
            aria-controls="userprofile"
            aria-selected="false"
          >
            Profile
          </button>
        </li> */}
        </ul>
        <div class="tab-content pl-110" id="myTabContent">
          <section
            class="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            {user[0] ? (
              <div>
                <p class="text-dark-gray mb-0">
                  Welcome back,{" "}
                  <strong class="font-weight-500">
                    {jwt_decode(localStorage.getItem("token")).username}
                  </strong>
                </p>
                <h2 class="text-dark-gray mb-0">Let's continue learning</h2>
                <div class="row mt-lg-4 mt-2">
                  <div class="col-12 col-md-6 my-lg-0 my-3">
                    <MyLearningCard
                      user={user}
                      tcounter={tcounter}
                      continueLearning={continueLearning}
                    />
                    {user.map((item, index) => {
                      return Array.from(
                        continueLearning,
                        (o) => o.courseId._id
                      ).indexOf(item._id) === -1 ? (
                        <>
                          <a
                            href="javascript:void()"
                            class="card mylearn-card mb-3 text-decoration-none"
                          >
                            <div class="row g-0">
                              <div class="col-xl-4 col-3 course-img">
                                {item.icon === "default.jpg" ? (
                                  <img
                                    src={`./../${item.icon}`}
                                    class="img-fluid"
                                    alt="Course Title"
                                    data-level="1"
                                    onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
                                  />
                                ) : item.icon.match(/http/g) ? (
                                  <img
                                    src={item.icon}
                                    class="img-fluid"
                                    alt="Course Title"
                                    data-level="1"
                                    onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
                                  />
                                ) : (
                                  <>
                                    <img
                                      src={`${global.imgURL}/${item.icon}`}
                                      class="img-fluid"
                                      alt={item.name}
                                      data-level="1"
                                      onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
                                    />
                                  </>
                                )}
                              </div>
                              <div class="col-xl-8 col-9">
                                <div class="card-body p-lg-3 p-2 d-flex flex-wrap h-100 flex-column justify-content-between">
                                  <h4 class="card-title text-black font-weight-400">
                                    {item.name
                                      .replaceAll("_", "  ")
                                      .replaceAll("-", " ")}
                                  </h4>
                                  <p
                                    class="card-text font-14 text-dark d-none"
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "3",
                                      WebkitBoxOrient: "vertical",
                                    }}
                                  >
                                    {item.desc}
                                  </p>
                                  <div class="d-flex justify-content-between pt-lg-3 pt-2 align-items-center">
                                    <div class="w-100">
                                      <p class="text-dark-gray font-14 mb-2">
                                        <span>you have not progress yet</span>{" "}
                                      </p>
                                      <div class="course-progressbar position-relative">
                                        <div class="position-absolute h-100 w-100 bg-border-color"></div>
                                        <div class="position-absolute h-100 w-100 bg-border-color"></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="d-flex justify-content-between pt-lg-3 pt-2 align-items-center">
                                    {/* {subcourses.map((subcourse) => {
                                      if (subcourse.name === item.name) {
                                        return (
                                          <Link
                                            to={`/course/${subcourse.type.name}/courseOverview/${item.name}/tplan`}
                                            class="text-primary d-lg-block d-none"
                                          >
                                            Continue
                                          </Link>
                                        );
                                      }
                                    })} */}
                                    <div
                                      // to={`/course/${subcourse.type.name}/courseOverview/${item.name}/tplan`}
                                      onClick={() => {
                                        const subCourse = getSubCourse(item.name);
                                        const urlString = handleContinue(subCourse.type.name, item.name, subCourse.tplans, subCourse.videos, subCourse.ppts);
                                        if(urlString) {
                                          navigate(urlString);
                                        }

                                      }}
                                      class="text-primary d-lg-block d-none"
                                    >
                                      Continue
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </>
                      ) : null;
                    })}
                  </div>
                  <div class="col-10 col-md-6 text-end mx-auto">
                    <img
                      width="514"
                      height="495"
                      alt="inProgress"
                      class="img-fluid"
                      src={
                        require("../../assets/images/inProgress2.png").default
                      }
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <p class="text-dark-gray mb-0">
                  Welcome back,{" "}
                  <strong class="font-weight-500">
                    {jwt_decode(localStorage.getItem("token")).username}
                  </strong>
                </p>
                <h2 class="text-dark-gray mb-3">
                  It looks you are not learning anything new today.
                </h2>
                <p class="text-dark-gray">Subscribe to a course now!</p>
                <div class="pt-5 text-center">
                  <img
                    width="636"
                    height="380"
                    alt="inProgress"
                    class="img-fluid"
                    src={require("../../assets/images/inProgress1.png").default}
                  />
                </div>
              </div>
            )}
          </section>
          <section
            class="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            {continueLearning[0] ? (
              <div>
                <p class="text-dark-gray mb-0">
                  Welcome back,{" "}
                  <strong class="font-weight-500">
                    {jwt_decode(localStorage.getItem("token")).username}
                  </strong>
                </p>
                {continueLearning.find(
                  (i) => i.courseId !== null && i.progress === 100
                ) ? (
                  <h2 class="text-dark-gray mb-0">
                    Congratulations on your achievement!
                  </h2>
                ) : (
                  <h2 class="text-dark-gray mb-0">
                    You have not completed till now!
                  </h2>
                )}
                <div class="row mt-lg-4 mt-2">
                  <div class="col-12 col-md-6 my-lg-0 my-3">
                    {continueLearning.map((item, index) => {
                      if (item.courseId !== null && item.progress === 100) {
                        return (
                          <MyContinueCard item={item} tcounter={tcounter} />
                          // <MyLearningCard />
                        );
                      }
                    })}
                  </div>
                  <div class="col-10 col-md-6 text-end mx-auto">
                    <img
                      width="514"
                      height="495"
                      alt="inProgress"
                      class="img-fluid"
                      src={require("../../assets/images/completed.png").default}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <p class="text-dark-gray mb-0">
                  Welcome back,{" "}
                  <strong class="font-weight-500">
                    {jwt_decode(localStorage.getItem("token")).username}
                  </strong>
                </p>
                <h2 class="text-dark-gray mb-3">
                  It looks you are not learning anything new today.
                </h2>
                <p class="text-dark-gray">Subscribe to a course now!</p>
                <div class="pt-5 text-center">
                  <img
                    width="636"
                    height="380"
                    alt="inProgress"
                    class="img-fluid"
                    src={require("../../assets/images/inProgress1.png").default}
                  />
                </div>
              </div>
            )}
          </section>
          <section
            class="tab-pane fade"
            id="result"
            role="tabpanel"
            aria-labelledby="result-tab"
          >
            {userResult && userResult.length > 0 ? (
              <>
                {/* <h5 className="choose color-dgreen">Your Result</h5> */}
                <h2 class="text-dark-gray mb-0">Your Result</h2>
                <table class="table mt-4 table-striped result_table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col" class="text-start">
                        Course Name
                      </th>
                      <th scope="col" class="text-start">
                        Batch Name
                      </th>
                      <th scope="col">Internal Mcq Mark</th>
                      <th scope="col">Internal Practical Mark</th>
                      <th scope="col">Internal Remark</th>
                      <th scope="col">External Mcq Mark</th>
                      <th scope="col">External Practical Mark</th>
                      <th scope="col">External Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userResult.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td class="text-start">{item?.subCourceId?.name}</td>
                          <td class="text-start">{item?.batchId?.name}</td>
                          <td>
                            <GetExamsModal examData={item?.internalExam?.mcq} userId={item?.userId} examType="internalExam" />
                          </td>
                          <td>
                            <GetExamsModal examData={item?.internalExam?.practical} userId={item?.userId} examType="internalExam" />
                          </td>
                          {item?.internalExam?.practical?.length > 0 ? (
                            <td>
                              {
                                item.internalExam.practical[
                                  item.internalExam.practical.length - 1
                                ].remark
                              }
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td>
                            <GetExamsModal examData={item?.externalExam?.mcq} userId={item?.userId} examType="externalExam" />
                          </td>
                          <td>
                            <GetExamsModal examData={item?.externalExam?.practical} userId={item?.userId} examType="externalExam" />
                          </td>
                          {item?.externalExam?.practical?.length > 0 ? (
                            <td>
                              {
                                item.externalExam.practical[
                                  item.externalExam.practical.length - 1
                                ].remark
                              }
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <div>
                <p class="text-dark-gray mb-0">
                  Welcome back,{" "}
                  <strong class="font-weight-500">
                    {jwt_decode(localStorage.getItem("token")).username}
                  </strong>
                </p>
                <h2 class="text-dark-gray mb-3">
                  you haven't yet appeared in any exam.
                </h2>
                <div class="pt-5 text-center">
                  <img
                    width="636"
                    height="380"
                    alt="inProgress"
                    class="img-fluid"
                    src={require("../../assets/images/inProgress1.png").default}
                  />
                </div>
              </div>
            )}
          </section>
        </div>
      </section>
    </>
  );
}

function GetExamsModal({ examData, userId, examType }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {examData?.length > 0 &&
        <>
          <Link onClick={() => handleShow()}>{examData[
            examData.length - 1
          ].marks.toFixed(2)}</Link>
          <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <div>Exam Data</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">
                      #
                    </th>
                    <th scope="col">
                      Mark(%)
                    </th>
                    {examData.find((item) => (item.remark ? item.remark : false)) ? <th scope="col">
                      Remarks
                    </th> : <th scope="col">
                      Answer
                    </th>}
                    <th scope="col">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody id="tableBody">
                  {
                    examData.map((marks, index) => {
                      const date = new Date(marks.reviewDate);
                      let temp = `${date.getDate()}/${date.getMonth() + 1
                        }/${date.getFullYear()}`;
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{marks && marks.marks.toFixed(2)}</td>
                          {marks?.remark ?
                            <td>{marks.remark}</td>
                            :
                            <td><Link to={`/correctanswer/${marks.examId}/${userId}/${examType}`} target="_blank"><BiCommentDetail
                            ></BiCommentDetail></Link></td>
                          }
                          <td>{temp}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </>
      }
    </>
  )

}

import React, { useState, useEffect, useRef } from "react";
import jwt_decode from "jwt-decode";
import CourseService from "../../services/CourseService";
import { useParams, Link, useNavigate } from "react-router-dom";
import $, { event } from "jquery";
import formloader from "../../images/formloading.svg";

export default function ExamModule(props) {
  var CryptoJS = require("crypto-js");
  const [timer, setTimer] = useState("00:00:00");
  const [examTable, setExamTable] = useState("");
  const [courseId, setCourseId] = useState("");
  const [batchId, setBatchId] = useState("");
  const [examQuestionId, setExamQuestionId] = useState([]);
  const [examSingleQuestion, setExamSingleQuestion] = useState({});
  const [examQuestionLength, setExamQuestionLength] = useState(0);
  const [startNumber, setStartNumber] = useState(0);
  const [endNumber, setEndNumber] = useState(1);
  const [milliSecond, setMillisecond] = useState(
    parseInt(localStorage.getItem("milliSecond")) || 0
  );
  const [loading, setLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [selectedMcq, setSelectedMcq] = useState([]);
  const [studentData, setStudentData] = useState([]);
  let { examId } = useParams();
  const Ref = useRef(null);
  const navigate = useNavigate();

  $(".ExamPage").bind("copy paste", function (e) {
    e.preventDefault();
    return false;
  });

  // document.addEventListener("contextmenu", (e) => {
  //   e.preventDefault();
  // });

  // function ctrlShiftKey(e, keyCode) {
  //   return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
  // }

  // document.onkeydown = (e) => {
  //   // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
  //   var keyCode = e.keyCode || e.which;

  //   if (
  //     keyCode == 123 ||
  //     ctrlShiftKey(e, "I") ||
  //     ctrlShiftKey(e, "J") ||
  //     ctrlShiftKey(e, "C") ||
  //     ctrlShiftKey(e, "U")
  //   )
  //     return false;
  // };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      const examQuestionId1 = localStorage.getItem("examQuestionId");
      const examTable1 = localStorage.getItem("examTable");
      const milliSecond1 = localStorage.getItem("milliSecond");
      const studentData1 = localStorage.getItem("studentData");
      const startNumber1 = localStorage.getItem("startNumber");
      const endNumber1 = localStorage.getItem("endNumber");
      const courseId1 = localStorage.getItem("courseId");
      const batchId1 = localStorage.getItem("batchId");
      const examQuestionLength1 = localStorage.getItem("examQuestionLength");
      if (
        examTable1 &&
        startNumber1 &&
        endNumber1 &&
        examQuestionId1 &&
        milliSecond1 &&
        courseId1 &&
        batchId1 &&
        examQuestionLength1
      ) {
        var bytes = CryptoJS.AES.decrypt(examQuestionId1, " rxweb-123");
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        var bytes1 = CryptoJS.AES.decrypt(studentData1, " rxweb-1234");
        var decryptedData1 = JSON.parse(bytes1.toString(CryptoJS.enc.Utf8));
        setExamQuestionId(decryptedData);
        setExamTable(JSON.parse(localStorage.getItem("examTable")));
        setStartNumber(JSON.parse(localStorage.getItem("startNumber")));
        setEndNumber(JSON.parse(localStorage.getItem("endNumber")));
        setSelectedMcq(JSON.parse(localStorage.getItem("selectedMcq")));
        setStudentData(decryptedData1);
        setCourseId(JSON.parse(localStorage.getItem("courseId")));
        setBatchId(JSON.parse(localStorage.getItem("batchId")));
        setExamQuestionLength(
          JSON.parse(localStorage.getItem("examQuestionLength"))
        );
        clearTimer(getDeadTime());
      } else {
        const userId = jwt_decode(localStorage.getItem("token")).id;
        CourseService.getExamQuestions(token, examId, userId).then((res) => {
          if (
            res.data == "exam Id Not Founded" ||
            res.data == "exam Questions Not Founded" ||
            res.data == "User Is Not Founded Batch"
          ) {
            if (res.data == "User Is Not Founded Batch") {
              setExamTable("User Not Found");
            } else {
              setExamTable("Clear");
            }
          } else {
            if (res.data.findUser && res.data.findUser.length > 0) {
              if (res.data.findUser[0].internalExam) {
                if (
                  res.data.findUser[0].internalExam.mcq.length === 0 &&
                  res.data.creatExam[0].typeOfExam === "Internal"
                ) {
                  setExamTable("Show");
                  setExamQuestionId(res.data.questionId);
                  setExamQuestionLength(res.data.questionId.length);
                  setMillisecond(res.data.totalTime);
                  setBatchId(res.data.creatExam[0].batchId);
                  setCourseId(res.data.creatExam[0].skillId);
                } else {
                  const findInternalUserExam =
                    res.data.findUser[0].internalExam.mcq.filter(
                      (item) => item.examId === examId
                    );
                  if (
                    findInternalUserExam.length > 0 &&
                    res.data.creatExam[0].typeOfExam === "Internal"
                  ) {
                    setExamTable("Clear");
                  } else {
                    if (res.data.creatExam[0].typeOfExam === "Internal") {
                      setExamTable("Show");
                      setExamQuestionId(res.data.questionId);
                      setExamQuestionLength(res.data.questionId.length);
                      setMillisecond(res.data.totalTime);
                      setBatchId(res.data.creatExam[0].batchId);
                      setCourseId(res.data.creatExam[0].skillId);
                    }
                  }
                }
              }
              if (res.data.findUser[0].externalExam) {
                if (
                  res.data.findUser[0].externalExam.mcq.length === 0 &&
                  res.data.creatExam[0].typeOfExam === "External"
                ) {
                  setExamTable("Show");
                  setExamQuestionId(res.data.questionId);
                  setExamQuestionLength(res.data.questionId.length);
                  setMillisecond(res.data.totalTime);
                  setBatchId(res.data.creatExam[0].batchId);
                  setCourseId(res.data.creatExam[0].skillId);
                } else {
                  const findExternalUserExam =
                    res.data.findUser[0].externalExam.mcq.filter(
                      (item) => item.examId.toString() === examId
                    );
                  if (
                    findExternalUserExam.length > 0 &&
                    res.data.creatExam[0].typeOfExam === "External"
                  ) {
                    setExamTable("Clear");
                  } else {
                    if (res.data.creatExam[0].typeOfExam === "External") {
                      setExamTable("Show");
                      setExamQuestionId(res.data.questionId);
                      setExamQuestionLength(res.data.questionId.length);
                      setMillisecond(res.data.totalTime);
                      setBatchId(res.data.creatExam[0].batchId);
                      setCourseId(res.data.creatExam[0].skillId);
                    }
                  }
                }
              }
            } else {
              setExamTable("Show");
              setExamQuestionId(res.data.questionId);
              setExamQuestionLength(res.data.questionId.length);
              setMillisecond(res.data.totalTime);
              setBatchId(res.data.creatExam[0].batchId);
              setCourseId(res.data.creatExam[0].skillId);
            }
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      if (examQuestionId && examQuestionId.length > 0) {
        const Id = examQuestionId[startNumber]._id;
        CourseService.getExamSingleQuestion(token, Id).then((res) => {
          setExamSingleQuestion(res.data[0]);
        });
      }
    }
  }, [examQuestionId, startNumber]);

  useEffect(() => {
    setLocalStorage(
      startNumber,
      endNumber,
      examTable,
      selectedMcq,
      courseId,
      examId,
      milliSecond,
      examQuestionId,
      batchId
    );
  }, [studentData, selectedMcq, confirmBox, startNumber, milliSecond]);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    // const hours = Math.floor(((total / 1000) * 60 * 60) % 24);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);

    if (total > 0) {
      const findSeconds = total / 1000;
      localStorage.setItem("milliSecond", findSeconds + 1);
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      setExamTable("Finish");
      localStorage.removeItem("startNumber");
      localStorage.removeItem("endNumber");
      localStorage.removeItem("examTable");
      localStorage.removeItem("selectedMcq");
      localStorage.removeItem("milliSecond");
      const token = localStorage.getItem("token");
      const studentData1 = localStorage.getItem("studentData");
      const courseId1 = JSON.parse(localStorage.getItem("courseId"));
      const batchId1 = JSON.parse(localStorage.getItem("batchId"));
      const examId1 = localStorage.getItem("examId");
      const examQuestionId1 = localStorage.getItem("examQuestionId");
      const examQuestionLength1 = JSON.parse(
        localStorage.getItem("examQuestionLength")
      );

      if (examId && batchId1) {
        var bytes = CryptoJS.AES.decrypt(examQuestionId1, " rxweb-123");
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        const bytes1 = CryptoJS.AES.decrypt(studentData1, " rxweb-1234");
        const decryptedData1 = JSON.parse(bytes1.toString(CryptoJS.enc.Utf8));
        const studentExamDeatils = {
          userId: jwt_decode(localStorage.getItem("token")).id,
          subCourceId: courseId1,
          questionsList: decryptedData1,
          batchId: batchId1,
          examId: examId,
          totalQuestion: decryptedData,
        };
        CourseService.postExamAnswer(token, studentExamDeatils)
          .then((res) => {
            localStorage.removeItem("studentData");
            localStorage.removeItem("examQuestionLength");
            localStorage.removeItem("courseId");
            localStorage.removeItem("batchId");
            localStorage.removeItem("examId");
            localStorage.removeItem("examQuestionId");
            clearTimer1();
            setTimeout(() => {
              navigate("/");
            }, 3000);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const clearTimer = (e) => {
    setTimer("00:00:00");
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const clearTimer1 = () => {
    if (Ref.current) {
      clearInterval(Ref.current);
    }
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + milliSecond);
    return deadline;
  };

  const onClickReset = () => {
    setExamTable("Start");
    clearTimer(getDeadTime());
    setStartNumber(0);
    setEndNumber(1);
    localStorage.setItem("examTable", JSON.stringify("Start"));
    localStorage.setItem("batchId", JSON.stringify(batchId));
  };

  const handleCheckbox = async (e, options, id) => {
    const options1 = {
      Id: options.Id,
      Value: options.Value,
    };
    const studentDatas = {
      id: id,
      option: [options1],
    };
    if (e.target.checked) {
      const findData = studentData.find((item) => item.id === id);
      setSelectedMcq([...selectedMcq, options]);
      if (findData) {
        findData.option.push(options1);
        setLocalStorage(
          startNumber,
          endNumber,
          examTable,
          selectedMcq,
          courseId,
          examId,
          milliSecond,
          examQuestionId,
          batchId
        );
      } else {
        setStudentData([...studentData, studentDatas]);
        setLocalStorage(
          startNumber,
          endNumber,
          examTable,
          selectedMcq,
          courseId,
          examId,
          milliSecond,
          examQuestionId,
          batchId
        );
      }
    } else {
      const findData = studentData.find((item) => item.id === id);
      const findMcqData = selectedMcq.find((item) => item._id === options._id);
      var index1 = selectedMcq.indexOf(findMcqData);
      var newArr1 = selectedMcq.splice(index1, 1);
      if (findData) {
        findData.option.splice(
          findData.option.findIndex((a) => a.Id === options.Id),
          1
        );
        if (findData.option.length === 0) {
          setStudentData(studentData.filter((item) => item.id !== id));
        }
        setConfirmBox(!confirmBox);

        setLocalStorage(
          startNumber,
          endNumber,
          examTable,
          selectedMcq,
          courseId,
          examId,
          milliSecond,
          examQuestionId,
          batchId
        );
      }
    }
  };

  const setLocalStorage = (
    startNumber,
    endNumber,
    examTable,
    selectedMcq,
    courseId,
    milliSecond,
    examId,
    examQuestionId,
    batchId
  ) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(examQuestionId),
      " rxweb-123"
    );
    var ciphertext1 = CryptoJS.AES.encrypt(
      JSON.stringify(studentData),
      " rxweb-1234"
    );
    localStorage.setItem("examQuestionId", ciphertext);
    localStorage.setItem("startNumber", startNumber);
    localStorage.setItem("endNumber", endNumber);
    localStorage.setItem("examTable", JSON.stringify(examTable));
    localStorage.setItem("studentData", ciphertext1);
    localStorage.setItem("examId", examId);
    localStorage.setItem("selectedMcq", JSON.stringify(selectedMcq));
    localStorage.setItem("courseId", JSON.stringify(courseId));
    localStorage.setItem("examQuestionLength", examQuestionLength);
    localStorage.setItem("batchId", JSON.stringify(batchId));
  };

  const handleRadio = (e, options, id) => {
    const options1 = {
      Id: options.Id,
      Value: options.Value,
    };
    const studentDatas = {
      id: id,
      option: [options1],
    };
    const data = {
      qId: id,
      optionId: options._id,
    };
    const findData = studentData.find((item) => item.id === id);
    const objIndex = selectedMcq.findIndex((obj) => obj.qId == id);

    if (objIndex > -1) {
      selectedMcq[objIndex].optionId = options._id;
      setConfirmBox(!confirmBox);

      setLocalStorage(
        startNumber,
        endNumber,
        examTable,
        selectedMcq,
        examId,
        courseId,
        milliSecond,
        examQuestionId,
        batchId
      );
    }
    if (findData) {
      var index = studentData.indexOf(findData);
      var newArr = studentData.splice(index, 1);
      setStudentData([...studentData, studentDatas]);
      setLocalStorage(
        startNumber,
        endNumber,
        examTable,
        selectedMcq,
        courseId,
        examId,
        milliSecond,
        examQuestionId,
        batchId
      );
    } else {
      setStudentData([...studentData, studentDatas]);
      setSelectedMcq([...selectedMcq, data]);
      setLocalStorage(
        startNumber,
        endNumber,
        examTable,
        selectedMcq,
        courseId,
        examId,
        milliSecond,
        examQuestionId,
        batchId
      );
    }
  };

  const handleNext = () => {
    setStartNumber(startNumber + 1);
    setEndNumber(endNumber + 1);
    setLocalStorage(
      startNumber,
      endNumber,
      examTable,
      selectedMcq,
      courseId,
      examId,
      milliSecond,
      examQuestionId,
      batchId
    );
  };
  const handleBack = () => {
    setStartNumber(startNumber - 1);
    setEndNumber(endNumber - 1);
    setLocalStorage(
      startNumber,
      endNumber,
      examTable,
      selectedMcq,
      courseId,
      examId,
      milliSecond,
      examQuestionId,
      batchId
    );
  };
  const handleSubmit = () => {
    if (studentData.length === examQuestionId.length) {
      setLoading(true);
      setExamTable("Finish");
      const token = localStorage.getItem("token");
      const studentExamDeatils = {
        userId: jwt_decode(localStorage.getItem("token")).id,
        subCourceId: courseId,
        questionsList: studentData,
        batchId: batchId,
        examId: examId,
        totalQuestion: examQuestionId,
      };
      CourseService.postExamAnswer(token, studentExamDeatils)
        .then((res) => {
          setLoading(false);
          clearTimer1();
          setTimeout(() => {
            navigate("/");
          }, 3000);
          localStorage.removeItem("milliSecond");
          localStorage.removeItem("startNumber");
          localStorage.removeItem("endNumber");
          localStorage.removeItem("examQuestionId");
          localStorage.removeItem("examTable");
          localStorage.removeItem("studentData");
          localStorage.removeItem("selectedMcq");
          localStorage.removeItem("milliSecond");
          localStorage.removeItem("examId");
          localStorage.removeItem("courseId");
          localStorage.removeItem("batchId");
          localStorage.removeItem("examQuestionLength");
        })
        .catch((err) => console.log(err));
    } else {
      alert("please fill answer in all questions");
    }
  };

  return (
    <>
      <div class="container pt-5 ExamPage pb-5">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="200" />
              </div>
              <div className="col-12 text-white h4">Loading...</div>
            </div>
          </div>
        )}
        {examTable === "Show" && (
          <div class="mt-5 text-danger h1 text-center">
            <div>Hello Students</div>
            <div>Please Click button to start your exam</div>
            <button class="btn btn-success" onClick={onClickReset}>
              Start Exam
            </button>
          </div>
        )}
        {examTable === "Clear" && (
          <div class="mt-5 text-danger h1 text-center">
            <div>Hello {jwt_decode(localStorage.getItem("token")).name}</div>
            <div>
              <h3>You are already given exam!!</h3>
            </div>
          </div>
        )}
        {examTable === "User Not Found" && (
          <div class="mt-5 text-danger h1 text-center">
            <div>
              <h3>You are not valid user for this exam</h3>
            </div>
          </div>
        )}
        {examTable === "Start" && (
          <>
            <div class="App text-end mt-5 mb-2">
              <button
                onClick={handleSubmit}
                id="submitExam"
                class="btn btn-success"
              >
                Submit Exam
              </button>
            </div>
            <div className=" border border-dark shadow-md mb-5 pb-5">
              <div class="d-flex justify-content-between">
                <div class="ms-2">
                  {/* <h3 class="text-center">
                    Total Question {examQuestionId.length}
                  </h3> */}
                </div>
                <div>
                  <h3>{timer}</h3>
                </div>
              </div>
              {examSingleQuestion && examSingleQuestion._id && (
                <div class="ms-4">
                  <div className="d-flex justify-content-between mb-3">
                    <div>
                      {examSingleQuestion.questionTopic && (
                        <h3 class="text-primary">
                          {examSingleQuestion.questionTopic}
                        </h3>
                      )}
                    </div>
                    <div>
                      <h3 class="text-secondary">
                        Question {startNumber + 1} of {examQuestionId.length}:
                      </h3>
                    </div>
                    <div>
                      {examSingleQuestion.questionWeightage ? (
                        <h3 class="text-success text-end me-2 ">
                          {examSingleQuestion.questionWeightage} Marks
                        </h3>
                      ) : (
                        <h3 class="text-success text-end me-2 ">1 Marks</h3>
                      )}
                    </div>
                  </div>

                  {/<\/?[a-z][\s\S]*>/i.test(examSingleQuestion.Question) ? (
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: examSingleQuestion.Question,
                      }}
                    ></h3>
                  ) : (
                    <h3>{examSingleQuestion.Question}</h3>
                  )}

                  {examSingleQuestion.Option.map((options) => {
                    return (
                      <>
                        {examSingleQuestion.Type === 1 ? (
                          <div className="form-check" key={options._id}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="hobbies"
                              id={options._id}
                              checked={
                                selectedMcq &&
                                selectedMcq.length > 0 &&
                                selectedMcq.some(
                                  (item) => item._id === options._id
                                )
                              }
                              onClick={(e) =>
                                handleCheckbox(
                                  e,
                                  options,
                                  examSingleQuestion._id
                                )
                              }
                            />
                            {/<\/?[a-z][\s\S]*>/i.test(options.Value) ? (
                              <label
                                className="form-check-label"
                                htmlFor={options._id}
                                dangerouslySetInnerHTML={{
                                  __html: options.Value,
                                }}
                              ></label>
                            ) : (
                              <label
                                className="form-check-label"
                                htmlFor={options._id}
                              >
                                {options.Value}
                              </label>
                            )}
                          </div>
                        ) : (
                          <div className="form-check" key={options.id}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="hobbies"
                              id={options._id}
                              checked={
                                selectedMcq &&
                                selectedMcq.length > 0 &&
                                selectedMcq.some(
                                  (item) => item.optionId === options._id
                                )
                              }
                              onClick={(e) => {
                                handleRadio(e, options, examSingleQuestion._id);
                              }}
                            />
                            {/<\/?[a-z][\s\S]*>/i.test(options.Value) ? (
                              <label
                                className="form-check-label"
                                htmlFor={options._id}
                                dangerouslySetInnerHTML={{
                                  __html: options.Value,
                                }}
                              ></label>
                            ) : (
                              <label
                                className="form-check-label"
                                htmlFor={options._id}
                              >
                                {options.Value}
                              </label>
                            )}
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              )}
              <div class="d-flex justify-content-around">
                {startNumber > 0 && (
                  <div>
                    <button onClick={handleBack} class="btn btn-warning">
                      Back
                    </button>
                  </div>
                )}
                {startNumber < examQuestionId.length - 1 && (
                  <div class="text-end ">
                    <button onClick={handleNext} class="btn btn-dark">
                      Next
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {examTable === "Finish" && (
          <div className="App border border-dark shadow-md mt-5">
            <h2>Your Exam is Finished</h2>
          </div>
        )}
      </div>
    </>
  );
}

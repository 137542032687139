// const HOST = "http://localhost";
// const CLIENT = "http://localhost:3000";
// const SERVER = "http://localhost:5000";

// const HOST = "http://192.168.102.249";
// const CLIENT = "http://192.168.102.249:3000";
// const SERVER = "http://192.168.102.249:5000";

/**** Staging URLs ****/
// const HOST = "http://rxtutorial.radixdev77.com";
// const CLIENT = "http://rxtutorial.radixdev77.com";
// const SERVER = "http://rxtutorial.radixdev77.com:5000";

const HOST = "https://apitutorial.dev.radixweb.net";
const CLIENT = "https://tutorial.dev.radixweb.net"
const SERVER = "https://apitutorial.dev.radixweb.net:443"

const url = {
  TUTORIAL_API_CATEGORY_URL: `${SERVER}/api/category`,
  TUTORIAL_API_COURSE_URL: `${SERVER}/course/`,
  TUTORIAL_API_SUBCOURSE_URL: `${SERVER}/api/subcourse`,
  TUTORIAL_API_BATCH_URL: `${SERVER}/api/batch`,
  TUTORIAL_API_TPDF_URL: `${SERVER}/api/trainingPDF`,
  TUTORIAL_API_CONTENT_URL: `${SERVER}/api/updatecontent/`,
  TUTORIAL_API_API_URL: `${SERVER}/api/course/`,
  TUTORIAL_API_TRAINING_URL: `${SERVER}/api/training-progress/`,
  TUTORIAL_API_Exam_URL: `${SERVER}/api/exam-api/`,
  TUTORIAL_API_VIDEO_URL: `${SERVER}/api/video`,
  TUTORIAL_API_TESTCASE_URL: `${SERVER}/api/testCase/`,
  TUTORIAL_API_LOGIN_URL: `${SERVER}/`,
  TUTORIAL_API_API_USER: `${SERVER}/api/user/`,
  TUTORIAL_API_ADDDAYINBATCH_URL: `${SERVER}/api/adddayinbatch/`,
  TUTORIAL_API_BATCHMANAGEMENT_URL: `${SERVER}/api/batchmanagement/`,
  Tutorial_API_POSTTESTCASE_URL: `${SERVER}/api/testCase/postAction`,
  Tutorial_API_UPDATETESTCASE_URL: `${SERVER}/api/testCase/updateAction`,
  Tutorial_API_GETTESTCASE_URL: `${SERVER}/api/testCase/`,
  Tutorial_API_GETTESTCASEACTIONS_URL: `${SERVER}/api/testCase/getAllActions/`,
  Tutorial_API_DeleteTestCaseAction_URl: `${SERVER}/api/testcase/`,
  Tutorial_API_GETTESTCASEACTIONBYID_URL: `${SERVER}/api/testcase/`,
  Tutorial_API_postSpecData_Url: `${SERVER}/api/spec/postSpec`,
  Tutorial_API_GETSpecData_Url: `${SERVER}/api/spec/`,
  SONAR_URL: `${SERVER}/api/sonar/`,
  imgURL: `${SERVER}/public/images`,
  pptURL: `${SERVER}/public/ppts`,
  fileURL: `${SERVER}/public/files`,
  uploadUrl: `${SERVER}/public/uploads`,
  newVideoUrl: `${SERVER}/public/m3u8Video`,
  mp4VideoUrl: `${SERVER}/public/mp4ActualVideo`,
  examVideo: `https://d2c967qfslpzim.cloudfront.net/trainees`,
  CLOUDNARY_IMG_UP: `https://api.cloudinary.com/v1_1/tutorialradix/image/upload`,
  CLOUDNARY_RAW_UP: `https://api.cloudinary.com/v1_1/tutorialradix/raw/upload`,
  SERVER,
  host: `${HOST}`,
  url: CLIENT,
  // confirmationAverageDetail: {
  //   specialSkills: [
  //     {
  //       skill: "TrainingProject",
  //       proportion: 60,
  //       columns: [
  //         "Communication",
  //         "Analytics",
  //         "Database",
  //         "UI",
  //         "Serverside",
  //         "IP",
  //         "Communication",
  //         "Analytics",
  //         "Database",
  //         "UI",
  //         "Serverside",
  //         "EP",
  //       ],
  //     },
  //     {
  //       skill: "LiveCodingTest",
  //       proportion: 10,
  //       columns: [
  //         "IP",
  //         "EP",
  //       ],
  //     },
  //   ],
  //   rest: 25,
  //   academic: 5,
  // },
  confirmationAverageDetail: {
    specialSkills: [
      {
        skill: "TrainingProject",
        id: "62724b636fee5c70b251b8cb",
        proportion: 0.6, // means 60%
        columns: [
          "Communication",
          "Analytics",
          "Database",
          "UI",
          "Serverside",
          "IP",
          "Communication",
          "Analytics",
          "Database",
          "UI",
          "Serverside",
          "EP",
          "Average",
          "Grade"
        ],
      },
      {
        skill: "LiveCodingTest",
        id: "62f3b98a20efc67bbb6d37c1",
        proportion: 0.10, // means 10%
        columns: [
          "IP",
          "EP",
          "Average",
          "Grade"
        ],
      },
    ],
    commanSkill: {
      proportion: 0.25,
      columns: ["IM", "EM", "IP", "EP", "Average", "Grade"]
    }, // means 25%
    academic: 0.05, // means 5%
  },
   
  image: {
    defaultImageName: "default.png"
  }
};

export default url;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import formloader from "../../images/formloading.svg";
import CourseService from "../../services/CourseService";

export default function AddUpdateSubCourse() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();

  const [allcourse, setallcourse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [alertConfirmBox, setAlertConfirmBox] = useState(false);
  const [validate, setValidate] = useState(false);
  const [msg, setMsg] = useState({ message: "", status: false });

  const level = ["Beginner", "Intermediate", "Advanced"];
  const [allSubcoursesData, setAllSubcoursesData] = useState([]);

  const [postSubcourse, setPostSubcourse] = useState({
    name: "",
    type: "",
    icon: "",
    desc: "",
    svg: "",
    level: "",
    status: "",
    color: "",
  });

  const [specialCharacterValidate, setSpecialCharacterValidate] =
    useState(false);

  useEffect(() => {
    if (id && state) {
      setPostSubcourse({
        name: state.name,
        type: state.type._id,
        icon: state.icon,
        desc: state.desc,
        status: state.status,
        color: state.color,
        svg: "",
        level: state.level,
      });
    }

    CourseService.getCourses()
      .then((res) => {
        setallcourse(res.data);
      })
      .catch((ex) => console.log(ex));
    CourseService.getSubCourses()
      .then(async (res) => {
        if (res.data.length > 0) {
          const newAllSubcourseData = res.data
            .map((elem) => elem.icon)
            .filter((elem, index, arr) => arr.indexOf(elem) === index);
          setAllSubcoursesData(newAllSubcourseData);
        }
        setLoading(false);
      })
      .catch((ex) => console.log(ex));
  }, []);

  const handleCourse = async (e) => {
    e.preventDefault();
    setValidate(true);
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      const { name, type, icon, desc, svg } = postSubcourse;
      if (/^[a-zA-Z\s]*$/.test(name)) {
        // allow only alphabets and numbers
        if (name && type && desc && (icon || svg)) {
          const formData = new FormData();
          formData.append("data", JSON.stringify(postSubcourse));
          formData.append("icon", postSubcourse.svg);
          setLoading(true);
          if (!id) {
            CourseService.postSubCourse(formData, token)
              .then((res) => {
                if (res.data.msg && res.data.status) {
                  setConfirmBox(!confirmBox);
                  setMsg({ message: res.data.msg, status: res.data.status });
                  setAlertConfirmBox(true);
                } else {
                  setMsg({ message: res.data.msg, status: res.data.status });
                  setAlertConfirmBox(true);
                }
              })
              .catch((ex) => console.log(ex));
          } else {
            CourseService.putSubCourses(formData, id, token)
              .then((res) => {
                if (res.data.msg && res.data.status) {
                  setConfirmBox(!confirmBox);
                  setMsg({ message: res.data.msg, status: res.data.status });
                  setAlertConfirmBox(true);
                } else {
                  setMsg({ message: res.data.msg, status: res.data.status });
                  setAlertConfirmBox(true);
                }
              })
              .catch((ex) => console.log(ex));
          }
          setTimeout(() => {
            setAlertConfirmBox(false);
          }, 3000);

          setValidate(false);
          setPostSubcourse({
            name: "",
            type: "",
            svg: "",
            icon: "",
            desc: "",
            level: "",
            status: "",
            color: "",
          });
          document.getElementById("myform").reset();
        }
      } else if (name.length > 0) {
        setSpecialCharacterValidate(false);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0">
            <li
              className={"breadcrumb-item text-primary"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/admin/course/manage-subcourse`);
              }}
            >
              Manage SubCourses
            </li>

            <li className={"breadcrumb-item active"}>
              {id ? "Edit" : "Add"} SubCourses
            </li>
          </ol>
        </nav>
      </div>

      <div className="container p-2 px-3">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">Loading subcourse...</div>
            </div>
          </div>
        )}
        {alertConfirmBox && (
          <div className="formloade">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <div
                  className={`alert ${
                    msg.status ? "alert-success" : "alert-danger"
                  }`}
                >
                  {msg.message}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="formloade">
          <div className="row">
            <div className="col-6 w-100 confirmbox">
              <form
                onSubmit={handleCourse}
                className="px-lg-5 py-3"
                method="post"
                id="myform"
              >
                <div className="mb-3 position-relative">
                  <label htmlFor="type" className="form-label">
                    Select Course
                  </label>
                  <select
                    className="form-select"
                    id="type"
                    value={postSubcourse.type}
                    style={
                      (postSubcourse.type || !validate) &&
                      postSubcourse.type !== "Common"
                        ? null
                        : {
                            border: "1px solid #df4957",
                            boxShadow: "0 0 5px #df4957",
                          }
                    }
                    onChange={(e) => {
                      // console.log("e.target.value", e);
                      setPostSubcourse((d) => ({
                        ...d,
                        type: e.target.value,
                      }));
                    }}
                  >
                    <option value="Common">Please select subcourse type</option>
                    {allcourse.map((cat, index) => {
                      return <option value={cat._id}>{cat.name}</option>;
                    })}
                  </select>
                </div>
                {((!postSubcourse.type && validate) ||
                  postSubcourse.type === "Common") && (
                  <div
                    className="rounded px-2 py-1 text-white mt-1"
                    style={{
                      background: "rgba(220,53,69,.9)",
                      zIndex: "1",
                    }}
                  >
                    Please select subcourse type
                  </div>
                )}
                <div className="mb-3 position-relative">
                  <label htmlFor="name" className="form-label">
                    SubCourse Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder="Please Enter The Sub Course Name Ex: HTML"
                    value={postSubcourse.name}
                    style={
                      postSubcourse.name || !validate
                        ? null
                        : {
                            border: "1px solid #df4957",
                            boxShadow: "0 0 5px #df4957",
                          }
                    }
                    onChange={(e) => {
                      const { value } = e.target;
                      setPostSubcourse((d) => ({ ...d, name: value }));
                      // console.log(
                      //   "check validation",
                      //   /^[a-zA-Z\s]*$/.test(value)
                      // );
                      if (value.length > 0 && /^[a-zA-Z\s]*$/.test(value)) {
                        setSpecialCharacterValidate(true);
                      } else {
                        setSpecialCharacterValidate(false);
                      }
                    }}
                  />
                </div>
                {!postSubcourse.name
                  ? validate && (
                      <div
                        className="rounded px-2 py-1 text-white mt-1"
                        style={{
                          background: "rgba(220,53,69,.9)",
                          zIndex: "1",
                        }}
                      >
                        Please enter subcourse name
                      </div>
                    )
                  : !specialCharacterValidate &&
                    validate && (
                      <div
                        className="rounded px-2 py-1 text-white mt-1"
                        style={{
                          background: "rgba(220,53,69,.9)",
                          zIndex: "1",
                        }}
                      >
                        Please do not enter special character
                      </div>
                    )}
                {/* <div className="mb-3 position-relative">
                  <label htmlFor="level" className="form-label">
                    Select Course Level
                  </label>
                  <select
                    className="form-select"
                    id="level"
                    value={postSubcourse.level}
                    style={
                      (postSubcourse.level || !validate) &&
                      postSubcourse.level !== "NoValue"
                        ? null
                        : {
                            border: "1px solid #df4957",
                            boxShadow: "0 0 5px #df4957",
                          }
                    }
                    onChange={(e) =>
                      setPostSubcourse((d) => ({
                        ...d,
                        level: e.target.value,
                      }))
                    }
                  >
                    <option value="NoValue">Select Level</option>
                    {level.map((cat, index) => {
                      return (
                        <option value={cat} key={index}>
                          {cat}
                        </option>
                      );
                    })}
                  </select>
                  {((!postSubcourse.level && validate) ||
                    postSubcourse.level === "NoValue") && (
                    <div
                      className="position-absolute rounded px-2 py-1 text-white mt-1"
                      style={{
                        background: "rgba(220,53,69,.9)",
                        zIndex: "1",
                      }}
                    >
                      Please select subcourse level
                    </div>
                  )}
                </div> */}
                <div className="mb-3 position-relative">
                  <p>
                    <span className="text-danger pe-2">*Note :</span>
                    Select icons from below list. If you not find what you want
                    then select
                    <span className="text-primary ps-2">default.png</span> .If
                    want to add new then upload file from icon upload filed.
                  </p>
                  <label htmlFor="icon" className="form-label">
                    Select icon
                    {/* (if icon img not show then copy image(link) src from below link(find icons)) */}
                  </label>
                  <select
                    className="form-select"
                    id="icon"
                    value={postSubcourse.icon}
                    onChange={(e) => {
                      // console.log(
                      //   ">>>>>>",
                      //   e.target.selectedOptions[0].label
                      // );
                      setPostSubcourse((d) => ({
                        ...d,
                        icon: e.target.value,
                      }));
                    }}
                  >
                    <option value="Common">Select Icon</option>
                    {allSubcoursesData.map((cat, index) => {
                      return <option value={cat}>{cat}</option>;
                    })}
                  </select>
                </div>
                <div class="mb-3">
                  <label for="myfile">Icon Upload:</label>
                  <input
                    type="file"
                    id="myfile"
                    name="myfile"
                    className="form-control"
                    // value={form.svg}
                    onChange={(e) => {
                      // console.log("e.target.value", e.target.files[0]);
                      setPostSubcourse((d) => ({
                        ...d,
                        svg: e.target.files[0],
                      }));
                    }}
                  />
                </div>
                {((!postSubcourse.icon && !postSubcourse.svg && validate) ||
                  postSubcourse.icon == "Common") && (
                  <div
                    className="rounded px-2 py-1 text-white mt-1"
                    style={{
                      background: "rgba(220,53,69,.9)",
                      zIndex: "1",
                    }}
                  >
                    Please select icon to upload
                  </div>
                )}
                <div className="mb-5 position-relative">
                  <label htmlFor="desc" className="form-label">
                    Add description
                  </label>
                  <textarea
                    className="form-control"
                    id="desc"
                    value={postSubcourse.desc}
                    style={
                      postSubcourse.desc || !validate
                        ? null
                        : {
                            border: "1px solid #df4957",
                            boxShadow: "0 0 5px #df4957",
                          }
                    }
                    onChange={(e) =>
                      setPostSubcourse((d) => ({
                        ...d,
                        desc: e.target.value,
                      }))
                    }
                  ></textarea>
                  {!postSubcourse.desc && validate && (
                    <div
                      className="rounded px-2 py-1 text-white mt-1"
                      style={{
                        background: "rgba(220,53,69,.9)",
                        zIndex: "1",
                      }}
                    >
                      Please enter subcourse description
                    </div>
                  )}
                </div>
                <button
                  type="reset"
                  className="btn btn-secondary me-3"
                  onClick={() => {
                    setPostSubcourse({
                      name: "",
                      type: "",
                      icon: "",
                      desc: "",
                      level: "",
                      svg: "",
                      status: "",
                      color: "",
                    });
                    setValidate(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  {id ? "Edit Subcourse" : "Add Subcourse"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
